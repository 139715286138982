/* special selector used to split the final output file in order to isolate customisations for use with the atlas bootstrap 2 files - do not delete this selector */

php.css-split-selector {
  display: none;
}

body {
  font-family: $sans-serif;
  font-weight: 300;
  color: $text-colour;
  background-color: $white;
  font-size: 17px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $sans-serif-alt;
  font-weight: 600;
}

h1,
.h1,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: var(--brand-primary);
}

.page-header {
  margin-bottom: 10px;
  border-bottom: 0px none;
  h1 {
    color: var(--brand-accent);
    text-transform: uppercase;
    font-size: 25px;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
  }
}

.page,
.blog,
.post {
  .page-header {
    @extend .col-md-offset-3;
  }
}

.h2,
h2 {
  color: var(--brand-accent);
  font-size: 25px;
}

h1,
.h1 {
  margin-top: 0;
}

.aside-width {
  h4,
  .h4 {
    color: var(--brand-accent);
    margin-top: 25px;
    margin-bottom: 15px;
  }
}

.special-padding {
  padding: 30px 5vw;
}

blockquote {
  border-left: medium none;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  color: var(--brand-primary);
  font-family: $sans-serif-alt;
  line-height: 1.3;
  margin: 15px 30px 15px 0;
  padding: 10px 5px;
  h1 {
    font-size: 1.5em;
    padding: 0;
  }
  h2 {
    font-size: 1.2em;
  }
  h3 {
    font-size: 1em;
  }
}

div[style*="url("] {
  .bg-transparent,
  h1,
  h2,
  h3,
  h4 {
    color: white;
  }
}

a {
  color: var(--text-link-colour);
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.bg-transparent {
  background-color: transparent;
  color: $text-colour;
  a,
  a:visited {
    color: var(--text-link-colour);
  }
  .bg-colour-pad {
    padding: 0px;
  }
  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: (--text-link-colour);
    border-color: white;
  }
}

.bg-brand-primary {
  background-color: var(--brand-primary);
  color: white;
  a,
  a:visited {
    color: var(--brand-accent);
  }
  .container,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
  .form-control {
    border: 1px solid white;
    color: white;
  }
  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: transparent;
    border-color: white;
  }
}

.bg-brand-primary-alt {
  background-color: var(--brand-primary-alt);
  color: white;
  a,
  a:visited {
    color: var(--brand-accent);
  }
  .container,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
  .form-control {
    border: 1px solid white;
    color: white;
  }
  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: transparent;
    border-color: white;
  }
}

.bg-brand-accent {
  background-color: var(--brand-accent);
  color: white;
  a,
  a:visited {
    color: white;
  }
  .container,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
  .form-control {
    border: 1px solid white;
    color: white;
  }
  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: transparent;
    border-color: white;
  }
}

.bg-light-grey {
  background-color: $light-grey;
  color: $text-colour;
  a,
  a:visited {
    color: var(--brand-accent);
  }
  .container,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--brand-primary-alt);
    a,
    a:visited {
      color: var(--brand-primary);
    }
  }
  &.active:focus,
  .form-control {
    border: 1px solid white;
    color: $text-colour;
  }
  .btn.btn-default,
  .gform_body .button {
    color: black;
    background-color: transparent;
    border-color: black;
  }
}

.bg-grey {
  background-color: $grey;
  color: white;
  a,
  a:visited {
    color: white;
  }
  .form-control {
    border: 1px solid white;
    color: white;
  }
  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: transparent;
    border-color: white;
  }
}

.bg-white {
  background-color: white;
  color: $text-colour;
  a,
  a:visited {
    color: var(--brand-accent);
  }
  .container,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--brand-primary-alt);
    a,
    a:visited {
      color: var(--brand-primary);
    }
  }
  &.active:focus,
  .form-control {
    border: 1px solid white;
    color: $text-colour;
  }
  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: (--brand-accent);
    border-color: white;
  }
}

.btn {
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.btn-transparent {
  @extend .bg-transparent;
  color: white !important;
  border-color: rgba(255, 255, 255, 0.5);
  &:active,
  &:hover {
    border-color: white !important;
    color: white;
  }
}

div[style*="url("] {
  .btn-transparent {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.btn-brand-primary,
.btn-primary {
  @extend .bg-brand-primary;
  color: white !important;
  border-color: rgba(255, 255, 255, 0.5);
  &:active,
  &:hover {
    background-color: var(--brand-primary);
    border-color: white !important;
    color: white;
  }
}

.btn-brand-primary-alt,
.btn-primary-alt {
  @extend .bg-brand-primary-alt;
  color: white !important;
  border-color: rgba(255, 255, 255, 0.5);
  &:active,
  &:hover {
    background-color: var(--brand-primary-alt);
    border-color: white !important;
    color: white;
  }
}

.btn-brand-accent {
  @extend .bg-brand-accent;
  color: white !important;
  border-color: rgba(255, 255, 255, 0.5);
  &:active,
  &:hover {
    border-color: white !important;
    color: white;
  }
}

.btn-light-grey {
  @extend .bg-light-grey;
  color: $text-colour !important;
  &:active,
  &:hover {
    border-color: $text-colour !important;
    color: white;
  }
}

.btn-grey {
  @extend .bg-grey;
  color: white !important;
  &:active,
  &:hover {
    border-color: white !important;
    color: white;
  }
}

.btn-white {
  @extend .bg-white;
  color: $text-colour !important;
  &:active,
  &:hover {
    border-color: $text-colour !important;
    color: white;
  }
}

.jumptop {
  position: absolute;
  top: 0;
  left: 0;
}

.brand {
  display: inline-block;
  margin: 7px 0;
  width: 180px;
  img {
    display: block;
  }
}

.panzoom-focal {
  border: 2px dotted rgba(0, 0, 0, 0.25);
  padding: 15px;
  .panzoom-reset {
    position: absolute;
    top: 0;
    left: 0;
  }
}

nav,
.nav,
.ac_input {
  font-family: $sans-serif-alt;
}

.navbar,
.splash {
  margin-bottom: 0;
  border: 0px none;
  min-height: auto;
  position: relative;
}

.splash,
.navigation {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
}

.splash[style*="url("] {
  background-size: cover;
  background-position: 50% 0%;
}

.splash,
.splash .carousel-inner {
  height: 47.5vh;
  max-height: 500px;
  z-index: 5;
}

.home {
  .splash,
  .splash .carousel-inner {
    height: 55vh;
    max-height: 600px;
  }
}

.navigation {
  z-index: 100;
  .navbar {
    border: 0px none;
  }
}

.navbar-xs {
  align-content: center;
  display: flex;
  justify-content: space-between;
  min-height: 65px;
  padding-left: 15px;
  position: relative;
  background-color: var(--brand-primary);
  .navbar-toggle {
    border-radius: 0;
    border-color: white;
    padding: 6px 10px;
    margin: 55px 20px;
    .icn-bar {
      border-radius: 1px;
      display: block;
      height: 2px;
      width: 22px;
      margin: 4px 0;
      background-color: white;
    }
    &:hover {
      background-color: var(--brand-accent);
      .icn-bar {
        background-color: $white;
      }
    }
  }
  .brand {
    img {
      border: 10px solid white;
    }
  }
}

/* PRIMARY NAV */

.dropdown-menu {
  border: 0 !important;
  box-shadow: none !important;
  padding: 0;
}

.navbar-primary {
  .navbar-nav {
    .dropdown-menu {
      background-color: var(--brand-accent);
      font-size: 1em;
      >li,
      li.active {
        a {
          padding: 7px 15px;
          color: white;
          &:hover,
          &:focus {
            background-color: var(--brand-primary);
            color: white;
            text-decoration: none;
            background-image: none;
          }
        }
      }
    }
  }
  .navbar-form {
    margin: 11px 0;
    input {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: white;
      height: 28px;
      padding: 0 0 0 10px;
      width: 75px;
      transition: all 1s;
      &:focus {
        width: 150px;
        background-color: white;
        color: $text-colour;
        border: 1px solid rgba(255, 255, 255, 0.25);
      }
    }
    .form-control::-moz-placeholder,
    .form-control::-webkit-input-placeholder,
    .form-control::-ms-input-placeholder {
      color: white !important;
    }
    .btn {
      padding-top: 3px;
      padding-bottom: 3px;
      color: white;
    }
  }
  .nav {
    >li {
      >a {
        color: white;
        &:focus,
        &:hover {
          background-color: var(--brand-accent);
          text-decoration: none;
        }
      }
    }
    .open {
      >a,
      &:focus,
      &:hover {
        background-color: var(--brand-accent);
        color: white;
        text-decoration: none;
      }
    }
    >li.active {
      >a,
      &:focus,
      &:hover {
        background-color: var(--brand-accent);
        color: white;
        text-decoration: none;
      }
    }
  }
  &.navbar-collapse {
    background-color: var(--brand-primary-dark-rgba);
  }
}

.social-links {
  i {
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    font-size: 18px;
    height: 30px;
    line-height: 30px;
    margin: 5px 2px;
    text-align: center;
    width: 30px;
    color: var(--brand-accent);
  }
}















/* CAROUSEL */

.carousel-fade {
  .carousel-inner {
    >.item {
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      display: block;
      position: absolute;
      z-index: 0;
      transition: none;
      transform: translate3d(0, 0, 0) !important;
      right: 0;
      bottom: 0;
    }
    >.active {
      opacity: 1;
      z-index: 3;
    }
    >.next.left,
    >.prev.right {
      transition: opacity 0.7s ease-in;
      opacity: 1;
      left: 0;
      z-index: 2;
    }
    >.active.left,
    >.active.right {
      z-index: 1;
    }
  }
  .carousel-control {
    z-index: 4;
    height: 160px;
    bottom: auto;
    top: calc(50% - 80px);
  }
}

.carousel {
  .carousel-control.left,
  .carousel-control.right {
    background-image: none !important;
    text-shadow: none;
    z-index: 50;
    width: 8vw;
  }
  .carousel-control::after {
    color: #2a5699;
    display: block;
    font-family: fontawesome;
    font-size: 100px;
    line-height: 1;
    position: absolute;
    top: calc(50% - 50px);
  }
  .right.carousel-control::after {
    content: "";
    right: 10%;
  }
  .left.carousel-control::after {
    content: "";
    left: 10%;
  }
  .carousel-indicators {
    width: 100%;
    li {
      border: 0px none;
      background: rgba(white, 1);
      &.active {
        height: 10px;
        width: 10px;
        background: var(--brand-accent);
        margin: 1px;
      }
    }
  }
}

.banner {
  margin-bottom: -1px;
  .carousel {
    @extend .carousel-fade;
    .carousel-inner {
      .item {
        background-position: 50% 45%;
        background-repeat: no-repeat;
        background-size: cover;
        .carousel-caption {
          align-content: center;
          color: #fff;
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-end;
          left: 5vw;
          right: 5vw;
          top: 0;
          text-align: left;
          >div {
            margin-bottom: 30px;
          }
          .btn {
            color: white;
            border-color: white;
            background-color: rgba(black, 0.1);
          }
          h1 {
            font-family: $sans-serif;
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 20px;
          }
          .carousel-description {
            margin-bottom: 40px;
          }
          h1,
          p {
            padding: 0;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}

.project-list {
  ul {
    display: flex;
    flex-flow: row wrap;
    list-style: outside none none;
    padding: 0;
    li {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      flex: 1 1 auto;
      font-family: PT Sans;
      font-size: 1.2em;
      font-weight: 600;
      margin: 0 10px 10px 0;
      padding: 5px 12px;
      position: relative;
      min-width: 20%;
      small {
        display: block;
        font-weight: 200;
      }
      a {
        color: white;
        display: block;
        &:hover {
          text-decoration: none;
          &:after {
            content: "";
            display: block;
            font-family: fontawesome;
            font-size: 14px;
            font-weight: 100;
            height: 12px;
            line-height: 12px;
            position: absolute;
            right: 3px;
            top: 3px;
            width: 12px;
          }
        }
      }
      &:hover {
        background-color: white;
        a {
          color: var(--brand-primary);
        }
      }
    }
  }
}

.flex-map {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.map-block {
  height: 55vh;
  max-height: 600px;
  position: relative;
}

.single-project {
  .gallery-block {
    .carousel {
      margin-top: 30px;
      .carousel-inner .item {
        //height: 65vh;
        //max-height: 700px;
      }
    }
  }
  .partners-block,
  .gallery-block,
  .news-block {
    h2,
    h3 {
      color: var(--brand-accent);
      text-transform: uppercase;
      text-align: center;
    }
    h3 {
      font-size: 18px;
    }
  }
  .partners-block {
    .col-md-6 {
      padding: 30px 15px;
    }
  }
  .partner-logos {
    display: flex;
    margin: 30px 0;
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
    align-content: center;
    a {
      background-origin: content-box;
      background-position: 50% center;
      background-repeat: no-repeat;
      background-size: contain;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      flex: 0 1 auto;
      height: 120px;
      margin: 15px 15px 0 0;
      padding: 10px;
      width: 150px;
    }
  }
}


















/* MAIN SEARCH */

.main-search-container {
  bottom: 5vh;
  display: block;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  z-index: 999;
  .main-search {
    display: block;
    margin: 0 auto;
    max-width: 380px;
    position: relative;
    padding: 0 10px;
    .btn {
      background: #fff none repeat scroll 0 0;
      border-left: 1px dotted #e4e4e4;
      padding: 8px 11px;
      position: absolute;
      right: 11px;
      top: 1px;
    }
    input {
      border: 1px solid #fff;
      box-shadow: none;
      font-size: 1em;
      height: 40px;
      padding: 8px 12px;
      color: #000;
      &.tt-hint {
        color: #999;
      }
    }
  }
}


























/* WordPress Gallery
------------------------------------------ */

.gallery {
  margin-bottom: 20px;
  &:after {
    display: table;
    content: "";
    clear: both;
  }
}

.gallery-item {
  display: inline-block;
  padding: 5px;
  .gallery-icon {
    border: 1px solid #e3e3e3;
    text-align: center;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

.gallery-columns-1 .gallery-item {
  width: 100%;
  float: none;
}

.gallery-columns-2 .gallery-item {
  width: calc(50% - 10px);
}

.gallery-columns-3 .gallery-item {
  width: calc(33.3333% - 10px);
}

.gallery-columns-4 .gallery-item {
  width: calc(25% - 10px);
}

.gallery-columns-5 .gallery-item {
  width: calc(20% - 10px);
}

.gallery-columns-6 .gallery-item {
  width: calc(16.6666% - 10px);
}

.gallery-columns-7 .gallery-item {
  width: calc(14.27% - 10px);
}

.gallery-columns-8 .gallery-item {
  width: calc(12.5% - 10px);
}

.gallery-columns-9 .gallery-item {
  width: calc(11.10% - 10px);
}

.gallery-columns-2 .gallery-item:nth-child(2n+1),
.gallery-columns-3 .gallery-item:nth-child(3n+1),
.gallery-columns-4 .gallery-item:nth-child(4n+1),
.gallery-columns-5 .gallery-item:nth-child(5n+1),
.gallery-columns-6 .gallery-item:nth-child(6n+1),
.gallery-columns-7 .gallery-item:nth-child(7n+1),
.gallery-columns-8 .gallery-item:nth-child(8n+1),
.gallery-columns-9 .gallery-item:nth-child(9n+1) {
  clear: left;
}

/**
 * content blocks
 */

.content-blocks {
  /*
  > div.container:first-child{
      margin-top: 40px;
  }
  */
  a[target="_blank"]::after {
    content: "\f08e";
    display: inline-block;
    color: inherit;
    font-family: fontawesome;
    font-size: 0.85em;
    padding-left: 5px;
    margin-right: 8px;
    vertical-align: middle;
  }
  .alignleft,
  .alignright {
    @extend .img-responsive;
  }
  >div.full-width:first-child {
    .content-block.panel_grid:first-child {
      margin-top: 0;
    }
  }
  .full_width_image {
    div[style*="url("] {
      position: relative;
      height: 45vh;
      background-size: cover;
      background-position: 50% 45%;
      transition: 0s linear;
      transition-property: background-position;
    }
  }
  .lead-intro {
    padding-top: 15px;
    p:first-of-type {
      font-size: 1.35em;
      font-weight: 300;
    }
    .woocommerce {
      padding-top: 20px;
    }
  }
  .main-width {
    img {
      @extend .img-responsive;
    }
    .call-out {
      @extend .col-md-4;
      background-color: transparent;
      position: absolute;
      top: 0;
      right: -33%;
      h4 {
        color: $text-colour;
      }
    }
  }
  .full-width {
    .panel_grid {
      .tab-pane.active {
        padding: 0;
      }
    }
  }
  .full-width:last-child {
    margin-bottom: 0;
  }

  .expanding_text {
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
    padding-bottom: 20px;
    .btn.btn-text {
      text-transform: uppercase;
      padding-left: 0;
      font-size: 0.9em;
      &:after {
        font-family: 'fontawesome';
        padding-left: 10px;
        content: "";
      }
      &.collapsed:after {
        content: "";
      }
    }
  }

  .full_width_image {
    div[style*="url("] {
      position: relative;
      .btn-photo-info {
        display: none;
      }
    }
  }



  .carousel {
    @extend .carousel-fade;
    .carousel-inner {
      //height: 55vh;
      //max-height: 500px;
      .item {
        background-position: 50% 45%;
        background-repeat: no-repeat;
        background-size: cover;
        //height: 55vh;
        //max-height: 500px;
        .carousel-caption {
          text-align: left;
          right: auto;
          left: 15%;
          top: 0px;
          color: white;
          .btn {
            color: white;
            border-color: white;
            background-color: rgba(black, 0.1);
          }
          h1 {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 30px;
            margin-bottom: 20px;
          }
          p {
            font-size: 1.35em;
          }
          .carousel-description {
            margin-bottom: 40px;
          }
          h1,
          p {
            padding: 0;
            text-shadow: 1px 1px 4px #000;
          }
        }
        .feature_panel {
          .columns {
            height: 55vh;
            max-height: 500px;
          }
        }
      }
    }
    .carousel-indicators {
      width: 100%;
      left: auto;
      text-align: right;
      right: 4%;
      bottom: 0;
      margin: 0;
    }
    .show-thumbnails {
      margin-left: auto;
      margin-right: auto;
      .carousel-inner {
        height: 80vh;
        max-height: 1000px;
        .item {
          .carousel-caption {
            background-color: rgba(0, 0, 0, 0.75);
            bottom: 0;
            color: #fff;
            left: 0;
            padding: 15px 30px;
            right: 0;
            text-align: left;
            top: auto;
            width: 100%;
          }
        }
      }
      .carousel-thumbnails {
        display: flex;
        flex-flow: row wrap;
        align-content: space-between;
        justify-content: flex-start;
        >a {
          flex: 1 1 33%;
          margin: 0;
          min-width: 30%;
          cursor: pointer;
          .thumbnail {
            margin-bottom: 0;
            height: 17.5vh;
            max-height: 200px;
            border-radius: 0;
            border: 0px none;
            background-size: cover;
            background-position: center center;
          }
        }
      }
    }
  }
  .panel_grid {
    .panel-group {
      margin-bottom: 0;
      .panel {
        box-shadow: none;
      }
      .panel-heading {
        .panel-title {
          >a {
            display: block;
            &:after {
              font-family: 'fontawesome';
              float: right;
              content: "";
            }
            &.collapsed:after {
              content: "";
            }
          }
        }
      }
    }
    .tab-pane.active {
      padding: 15px;
      margin-bottom: 20px;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
    .nav-tabs {
      >li {
        >a {
          font-weight: 600;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          border-top: 1px solid #ddd;
          border-radius: 0;
          color: $grey;
          margin-right: 4px;
        }
        &.active {
          >a,
          a:hover,
          a:focus {
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            border-top: 1px solid #ddd;
            border-radius: 0;
            color: $grey;
          }
        }
      }
    }
  }
  .call_to_action {
    .bg-overlay {
      background-color: rgba(0, 0, 0, 0.35);
      background-blend-mode: darken;
    }
    div[style*="url("] {
      background-position: center 35%;
      background-size: cover;
    }
    .cta-blocks {
      flex-direction: column;
      display: flex;
      flex: 1 1 auto;
      justify-content: stretch;
      &.cta-individual {
        .cta-content {
          margin-bottom: 10px;
        }
        div[style*="url("] {
          justify-content: flex-end;
          h2 {
            padding-bottom: 10px;
          }
          .block-buttons {
            padding-bottom: 20px;
          }
        }
      }
      .cta-split {
        display: flex;
        flex-flow: column nowrap;
        .cta-split-image {
          height: 50%;
          flex: 1 1 60%;
          width: 100%;
        }
        .cta-text-copy {
          padding: 15px 3vw;
          flex: 0 0 auto;
        }
      }
      .cta-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        p,
        dt {
          font-weight: 300;
          font-size: 1.1em;
        }
        dt {
          font-weight: 500;
          padding: .25em 0;
        }
        .cta-text-copy {
          h1,
          .h1,
          h2,
          .h2 {
            margin-bottom: 30px;
            &:first-of-type {
              margin-top: 0;
            }
          }
          .cta-title {
            margin-top: 20px;
          }
          .block-buttons {
            margin-top: 30px;
          }
          .post-list {
            padding-bottom: 20px;
            border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
          }
        }
        .text-left {
          .btn {
            margin: 0 15px 0 0;
          }
        }
        .text-left {
          .btn {
            margin: 0 0 0 15px;
          }
        }
        .text-center {
          .btn {
            margin: 0 15px;
          }
        }
        .block-buttons {
          margin-top: 0;
        }
        .col-md-3,
        .col-md-4,
        .col-md-6,
        .col-md-8,
        .col-md-9,
        .col-md-12 {
          padding: 30px 5vw;
        }
        .cta-icon {
          min-height: 10vh;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
        &.cta-split {
          padding: 0;
        }
      }
      &.cta-common {
        .block-buttons {
          margin-top: 15px;
        }
        .cta-content.col-md-12 {
          h2 {
            text-align: center;
            text-transform: uppercase;
            color: var(--brand-accent);
            text-shadow: 0 0 9px rgba(0, 0, 0, 0.9);
          }
          p {
            font-size: 40px;
            max-width: 700px;
            line-height: 1.2;
            font-weight: 700;
            color: white;
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
          }
          height: 55vh;
          max-height: 500px;
        }
      }
    }
  }
  .listings_grid {
    .listing-grid {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-end;
      align-items: stretch;
      >div {
        height: 55vh;
        max-height: 500px;
        flex: 1 1 auto;
        margin: 0 5px 5px 0;
      }
      .listing-item {
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        >a {
          bottom: 0;
          display: flex;
          justify-content: center;
          align-content: center;
          flex-flow: column;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          background-color: rgba(0, 0, 0, 0.5);
          transition: all 0.5s;
          &.lead {
            font-weight: 600;
            color: white;
            margin: 0;
            text-shadow: 0 0 4px black;
            &:hover {
              background-color: unset;
              transition: all 0.5s;
              text-decoration: none !important;
              text-shadow: 0 0 15px #000, 0 0 2px #000;
            }
          }
        }
        &[style*="url("] {
          background-position: 50% 45%;
          background-size: cover;
          background-repeat: no-repeat;
        }
        &.listing-item-text {
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
          text-align: left;
          img {
            max-width: 40%;
            display: inline-block;
            padding: 10px 0 10px 10px;
          }
        }
      }
      .column-text {
        padding: 20px 40px 30px;
        >a {
          padding: 20px 40px 30px !important;
        }
      }
    }
    .listing-group {
      padding: 0;
      margin-bottom: 0 !important;
      .listing-item {
        height: calc(50% - 5px);
        position: relative;
        width: 100%;
        margin-bottom: 5px;
        height: 49vh;
        max-height: 500px;
      }
    }
    .listing-grid-1,
    .listing-grid-2,
    .listing-grid-3 {
      >div.listing-item {
        >a {
          top: auto;
          height: 33%;
          padding: 15px;
          line-height: 1;
          text-align: left;
          text-shadow: none !important;
          &:before {
            top: -200%;
            bottom: 0;
            content: "";
            display: block;
            left: 0;
            position: absolute;
            right: 0;
          }
          &:after {
            bottom: 15px;
            content: 'View details';
            position: absolute;
            display: block;
            font-weight: 300;
            font-size: 13px;
          }
          &:hover {
            background-color: inherit !important;
            &:after {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .listing-grid-4,
    .listing-grid-5,
    .listing-grid-6 {
      flex-flow: row wrap;
    }
    .listing-grid-5,
    .listing-grid-6 {
      >div.listing-item {
        height: 25vh;
        max-height: 350px;
        min-width: 45%;
      }
    }
  }
  .post_list,
  .post-list {
    margin-top: 0 !important;
    display: flex;
    flex-flow: column wrap;
    .post-item {
      margin-bottom: 15px;
      padding-bottom: 15px;
      display: flex;
      flex-flow: column wrap;
      flex: 1 0 50%;
      min-width: 220px;
      .post-meta {
        margin-top: 15px;
        >div {
          display: inline-block;
          margin-right: 20px;
        }
      }
      h2 {
        margin-top: 0px !important;
        margin-bottom: 10px !important;
      }
      small {
        color: opacity(0.8);
      }
      p:last-child,
      .post-extra p {
        margin-bottom: 10px;
      }
      .post-image {
        &[style*="url("] {
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          min-height: 200px;
          max-width: 300px;
          >a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
        a[style*="url("] {
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          min-height: 200px;
          display: block;
        }
        flex: 1 1 auto;
        margin-right: 15px;
        position: relative;
      }
      .post-text {
        flex: 1 1 auto;
        small a {
          text-transform: uppercase;
        }
      }
    }
    &.post-type-project {
      flex-flow: row wrap;
      .post-item {
        flex-flow: column wrap;
        .post-image {
          a[style*="url("] {
            min-height: 300px;
          }
        }
        .post-description {
          display: none;
        }
      }
    }
    &.post-type-partner {
      .post-item {
        .post-image {
          a[style*="url("] {
            background-size: contain;
            background-origin: content-box;
            padding: 10px 15px 0 0;
            background-position: top center;
          }
        }
      }
    }
  }
  .post-feed {
    .post-item {
      margin-bottom: 10px;
      padding-bottom: 5px;
      h2 {
        margin-top: 5px !important;
      }
      p {
        margin-bottom: 5px !important;
      }
    }
  }

  .events-tabs {
    .nav-tabs {
      >li {
        >a {
          font-weight: 600;
          border-left: 1px sol id #ddd;
          border-right: 1px solid #ddd;
          border-top: 1px solid #ddd;
          border-radius: 0;
          margin-right: 4px;
          text-transform: uppercase;
          color: $grey;
          background-color: $v-light-grey;
        }
        &.active {
          >a,
          a:hover,
          a:focus {
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            border-top: 1px solid #ddd;
            border-radius: 0;
            color: white;
            background-color: var(--brand-accent);
          }
        }
      }
    }
    .tab-content {
      margin-top: 20px;
      .event-list {
        @extend .post_list;
        article {
          .col-md-12 {
            border: 0px none !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }
          margin-bottom: 15px;
          h3 {
            margin-top: 0;
          }
        }
      }
    }
  }
  .gform_wrapper {
    form {
      margin-bottom: 40px !important;
    }
    ul.gfield_checkbox li input[type="checkbox"]:checked+label,
    ul.gfield_radio li input[type="radio"]:checked+label {
      font-weight: normal;
    }
  }
}

.background-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 56%;
}

figcaption {
  > p {
    padding: 1rem 0;
  }
}

//graves parent page 
.page {
  .aside-width.page-search {
    display: none;
  }
  .full-indent-width.page-header {
    margin-left: 0;
  }
  .floorplan-tools {
    margin-bottom: 10px;
    button {
      color: #2a5699;

      &:after {
        content: '';
      }
    }
  }
  .floorplan-parent {
    border: 2px dotted #ccc;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    padding: 0;
    background-color: rgba(42, 86, 153, 0.025);
    .floorplan {
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}

.zoomContainer {
  z-index: 100000;
  .zoomLens {
    border-color: white !important;
  }
}

//graves child page
.graves {
  .graves-content {
    .inscription {
      font-weight: 400;
    }

    .page-header-image {
      margin-bottom: 20px;
      img {
        background-position: 50% 45%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 65vh;
        max-height: 700px;
      }
    }
    @extend .carousel-fade;
    .carousel-inner {
      height: 65vh;
      max-height: 700px;
      .item {
        background-position: 50% 45%;
        background-repeat: no-repeat;
        background-size: contain;
        height: 65vh;
        max-height: 700px;
        .carousel-caption {
          text-align: left;
          right: auto;
          left: 15%;
          top: 0px;
          color: white;
          .btn {
            color: white;
            border-color: white;
            background-color: rgba(black, 0.1);
          }
          h1,
          h2 {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 30px;
            margin-bottom: 20px;
          }
          p {
            font-size: 1.35em;
          }
          .carousel-description {
            margin-bottom: 40px;
          }
          h1,
          h2,
          p {
            padding: 0;
            text-shadow: 1px 1px 4px #000;
          }
        }
        .feature_panel {
          .columns {
            height: 55vh;
            max-height: 500px;
          }
        }
      }
    }
    .carousel-indicators {
      width: 100%;
      left: auto;
      text-align: right;
      right: 4%;
      bottom: 0;
      margin: 0;
    }
    .gallery-block {
      .show-thumbnails {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column wrap;
        padding: 0;
        .image-selector {
          margin-bottom: 20px;
        }
      }
    }
  }
}





.fts-mashup img {
  max-width: 100%;
}

.page-subnav {
  clear: both;
  >ul {
    list-style: outside none none;
    padding: 0;
    >li {
      >a {
        &::after {
          content: "";
          font-family: fontawesome;
          position: absolute;
          right: 10px;
          color: $light-grey;
          z-index: 99;
          opacity: 0.5;
        }
      }
      &:first-child {
        >a {
          border-top: 1px solid $v-light-grey;
        }
      }
    }
  }

  .children {
    display: none;
    list-style: outside none none;
    padding: 0;
    li {
      @extend .small;
      a {
        padding: 8px 30px 8px 38px;
      }
    }
  }

  li {
    position: relative;
    margin-bottom: 2px;
    a {
      background-color: $v-light-grey;
      color: $grey;
      display: block;
      padding: 10px 30px;
    }
    a:hover {
      background-color: var(--brand-primary);
      color: white;
      text-decoration: none;
    }
  }

  li.current_page_item {
    li {
      margin-bottom: 0px;
    }
    >a {
      color: var(--brand-primary);
      font-weight: bold;
      text-decoration: underline;
      &::after {
        content: '';
        opacity: 1;
        color: var(--brand-primary);
      }
      &:hover {
        color: white;
        &::after {
          color: white;
        }
      }
    }
  }

  .current_page_ancestor,
  .current_page_item {
    >.children {
      display: block;
    }
  }
}


.main-width {
  @extend .col-md-9;
  @extend .clearfix;
  margin-bottom: 30px;
  .bg-colour-pad {
    padding: 30px;
  }
}

.full-width {
  @extend .clearfix;
  .full_width_image {
    div[style*="url("] {
      height: 55vh;
    }
  }

  .col-md-6,
  .col-md-12 {
    @extend .special-padding;
  }
}

.full-indent-width {
  min-height: 1px;
  position: relative;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  .aside-width {
    display: none;
  }

  &.page-search {
    margin-top: 40px;
  }
}

.full-width,
.full-indent-width {
  .columns {
    .row {
      min-width: calc(100% + 30px);
    }
    .column-text {
      h3 {
        font-size: 32px;
      }
    }
  }
}

.aside-width {
  @extend .col-md-3;
  @extend .clearfix;
  margin-bottom: 30px;
  h3,
  h4 {
    text-transform: uppercase;
  }

  .aside-block {
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid #eee;
  }
}


.tt-menu {
  background-color: var(--brand-primary);
  width: 100%;
}

.tt-menu.tt-open {
  z-index: 9999 !important;
}

.tt-selectable {
  display: block;
  cursor: pointer;
  padding: 5px 15px;
  color: $white;
  strong {
    font-weight: 300 !important;
  }
}

.tt-selectable:hover {
  background-color: var(--brand-primary);
  color: $white;
}

button,
input,
select,
textarea,
.dropdown-menu {
  border-radius: 0 !important;
}


.feat-data {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  padding: 10px 0 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column;
  transition: all 0.5s;
  &::before {
    color: $white;
    content: "FEATURED SPECIES";
    display: block;
    font-family: "PT Sans", sans-serif;
    font-size: 13px;
    margin: 10px 0;
    text-align: center;
  }

  >h3 {
    color: $white;
    font-weight: normal;
    line-height: 175%;
    text-transform: uppercase;
    >small {
      color: $white;
      display: block;
      text-transform: none;
      font-size: 85%;
    }
  }

  .feat-links {
    margin-top: 10px;
    >p {
      margin: 0 0 5px;
    }
  }
}



/* ICON LINK */

a.icn-link {
  background-color: var(--brand-primary);
  color: $white;
  border-radius: 15px 4px 4px 15px;
  padding: 3px 10px 3px 2.25em;
  text-shadow: none;
  display: inline-block;
  position: relative;
  &:before {
    background-color: white;
    border-radius: 50%;
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    width: 26px;
    top: calc(50% - 13px);
    height: 26px;
  }

  &:after {
    content: '';
    font-family: fontawesome;
    color: $text-colour;
    display: block;
    position: absolute;
    left: 2px;
    top: calc(50% - 13px);
    width: 26px;
    line-height: 26px;
    font-size: 18px;
    text-align: center;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: $white;
    color: var(--brand-primary);
    text-decoration: none;
    transition: all 0.2s linear 0s;
    &:before {
      background-color: var(--brand-primary);
      transition: all 0.2s linear 0s;
    }
    &:after {
      color: white;
      transition: all 0.2s linear 0s;
    }
  }

  &.icn-map {
    &:after {
      content: '';
    }
  }

  &.icn-top {
    color: $grey;
    background-color: transparent;
    &:before {
      background-color: $text-colour;
    }
    &:after {
      content: '';
      color: white;
    }
  }
}

.breadcrumb-container {
  background-color: #f5f5f5;
  margin-bottom: 20px;
  .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
    padding: 10px 5vw;
    @extend .small;
  }
}

.cta-home {
  align-self: flex-end;
  flex: 0 1 auto;
  width: 100%;
  .cta {
    list-style: outside none none;
    margin: 0;
    padding: 0;
    .menu-item {
      i {
        font-size: 1.25em;
      }
      h3 {
        color: $white;
        display: block;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        pointer-events: none;
        padding: 5px 0;
        font-size: 20px;
      }
      a {
        min-height: 110px;
        display: flex;
        flex-flow: column nowrap;
        align-content: center;
        justify-content: space-around;
        background-color: $grey;
        border-top: 1px solid $white;
        color: #fff;
        font-weight: 300;
        text-align: center;
        padding: 5px 0;
        font-size: 22px;
        position: relative;
        &:focus,
        &:hover {
          text-decoration: none;
          background-color: var(--brand-primary);
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.50);
        }
      }
      small {
        color: $white;
        display: block;
        text-transform: none;
        line-height: 1.3;
        font-size: 17px;
      }
    }
  }
}

.info-panel,
.full-info-panel {
  background-position: center center;
  background-size: cover;
  color: #fff;
  min-height: 400px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.full-sponsor-panel {
  min-height: 0;
}

.info-panel .link-block {
  position: absolute;
  display: block;
  bottom: 15px;
}

.info-panel .col-md-6:first-child p {
  font-size: 110%;
}

.info-panel p {
  margin-top: 20px;
}

.full-panel {
  text-align: center;
}

.full-info-panel {
  padding: 30px 20%;
}

.full-panel h2,
.full-panel h2 {
  text-transform: uppercase;
  text-align: center;
  text-size: 34px;
}

.full-info-panel p {
  font-size: 1.3em;
  margin: 10px 0 20px;
}


.data-posts {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-content: center;
  text-align: center;
  >p {
    margin: 0 auto 10px;
    width: calc(100vw - 120px);
  }

  .post {
    margin-top: 20px;
    text-align: left;
    .post-img {
      background-image: url('/wp-content/themes/livinglandscapes/dist/images/default-project.png');
      background-position: 50% 65%;
      background-size: cover;
      height: 30vh;
      max-height: 250px;
      min-height: 150px;
      position: relative;
    }
    .post-copy {
      padding: 10px 0;
      display: flex;
      flex-flow: column nowrap;
      min-height: 150px;
      >p {
        flex: 1 1 auto;
      }
      >h3 {
        flex: 0 1 auto;
      }
    }
  }

  .posts-carousel {
    margin: 0 auto;
    width: 85%;
    .item {
      min-height: 0 !important;
      .post {
        padding: 15px;
      }
    }
    .cloneditem-1,
    .cloneditem-2,
    .cloneditem-3 {
      display: none;
    }
    .carousel-control {
      .fa {
        background-color: $grey;
        border-radius: 50%;
        height: 30px;
        line-height: 30px;
        position: absolute;
        top: -40px;
        width: 30px;
      }
      &.right {
        right: -7.5%;
      }
      &.left {
        left: -7.5%;
      }
      &::after {
        color: #fff;
        font-size: 1.5em;
        background: #555 none repeat scroll 0 0;
        border-radius: 50%;
        color: hsla(0, 0%, 100%, 0.2);
        display: block;
        font-family: fontawesome;
        height: 30px;
        line-height: 1;
        position: absolute;
        top: calc(50% - 50px);
        width: 30px;
      }
    }
  }

  .block-buttons {
    margin-bottom: 30px;
  }
}

footer {
  .footer-bottom {
    @extend .special-padding;
  }

  .footer-nav {
    list-style: none;
    padding-left: 0px;
    >li {
      border-bottom: 1px dotted $white;
      padding-bottom: 10px;
      &:last-child {
        border-bottom: 0px none;
      }
      >a {
        text-transform: uppercase;
        font-weight: 500;
        display: block;
      }
    }
    ul {
      padding-left: 0;
      list-style: none;
      a,
      a:visited {
        color: white;
      }
    }
  }

  .sponsors {
    //background-color: rgba($v-light-grey,0.35);
    height: 50vh;
    min-height: 350px;
    padding-top: 30px;
    padding-bottom: 40px;
    text-align: center;
    .sponsor-links {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      align-self: stretch;
      a {
        flex: 1 1 20%;
        text-align: center;
        margin: 20px 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 80px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .sponsor-no-logo {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      align-self: stretch;
      justify-content: center;
      margin-bottom: 120px;
      p {
        margin-right: 15px;
      }
    }
  }

  .social-links {
    padding: 17px 0 0;
    >a {
      display: inline-block;
      >span {
        display: none;
      }
    }
  }
}

small.gravestone-name + small.gravestone-name{
  display: block;
}

.back-to-top {
  color: $grey;
  padding: 20px 15px;
  display: inline-block;
}

.twitter-typeahead {
  display: block !important;
  postiion: static !important;
}

.image-caption {
  height: 50px;
  width: 50px;
  bottom: 0px;
  right: 0px;
  position: absolute;
  display: table;
  vertical-align: middle;
  table-layout: fixed;
  z-index: 900;
  >a {
    color: rgba(255, 255, 255, 0.5) !important;
    height: 50px;
    width: 50px;
    line-height: 35px;
  }

  >div {
    display: none;
    min-height: 50px;
    width: 100%;
    padding: 5px 15px 5px 50px;
    overflow: hidden;
  }

  &:hover,
  &:focus {
    width: 100%;
    .btn-photo-info {
      pointer-events: none;
      color: white;
      i {
        pointer-events: none;
      }
    }
    >a {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    >div {
      z-index: 999;
      opacity: 0;
      transition: opacity 0.5s;
      display: table-cell;
      color: white;
      vertical-align: middle;
      &:hover,
      &:focus {
        opacity: 1;
        transition: opacity 0.5s;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.main-container,
footer {
  .btn {
    &:after {
      content: '';
      font-family: fontawesome;
      margin-left: 10px;
      font-weight: 300 !important;
    }
  }
}

hr {
  clear: both;
  width: 100%;
}

.navbar-form .form-group {
  width: 75%;
  float: left;
}


@media all and (min-width: 768px) {
  .posts-carousel .carousel-inner>.active.left,
  .posts-carousel .carousel-inner>.prev {
    left: -50%;
  }

  .posts-carousel .carousel-inner>.active.right,
  .posts-carousel .carousel-inner>.next {
    left: 50%;
  }

  .posts-carousel .carousel-inner>.left,
  .posts-carousel .carousel-inner>.prev.right,
  .posts-carousel .carousel-inner>.active {
    left: 0;
  }

  .posts-carousel .carousel-inner .cloneditem-1 {
    display: block;
  }
}

@media all and (min-width: 768px) and (transform-3d),
all and (min-width: 768px) and (-webkit-transform-3d) {
  .posts-carousel .carousel-inner>.item.active.right,
  .posts-carousel .carousel-inner>.item.next {
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
    left: 0;
  }

  .posts-carousel .carousel-inner>.item.active.left,
  .posts-carousel .carousel-inner>.item.prev {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    left: 0;
  }

  .posts-carousel .carousel-inner>.item.left,
  .posts-carousel .carousel-inner>.item.prev.right,
  .posts-carousel .carousel-inner>.item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}

@media all and (min-width: 992px) {
  .posts-carousel .carousel-inner>.active.left,
  .posts-carousel .carousel-inner>.prev {
    left: -33.3%;
  }

  .posts-carousel .carousel-inner>.active.right,
  .posts-carousel .carousel-inner>.next {
    left: 33.3%;
  }

  .posts-carousel .carousel-inner>.left,
  .posts-carousel .carousel-inner>.prev.right,
  .posts-carousel .carousel-inner>.active {
    left: 0;
  }

  .posts-carousel .carousel-inner .cloneditem-2,
  .posts-carousel .carousel-inner .cloneditem-3 {
    display: block;
  }

  .posts-carousel .carousel-control {
    width: 5%;
  }
}

@media all and (min-width: 992px) and (transform-3d),
all and (min-width: 992px) and (-webkit-transform-3d) {
  .posts-carousel .carousel-inner>.item.active.right,
  .posts-carousel .carousel-inner>.item.next {
    -webkit-transform: translate3d(33.3%, 0, 0);
    transform: translate3d(33.3%, 0, 0);
    left: 0;
  }

  .posts-carousel .carousel-inner>.item.active.left,
  .posts-carousel .carousel-inner>.item.prev {
    -webkit-transform: translate3d(-33.3%, 0, 0);
    transform: translate3d(-33.3%, 0, 0);
    left: 0;
  }

  .posts-carousel .carousel-inner>.item.left,
  .posts-carousel .carousel-inner>.item.prev.right,
  .posts-carousel .carousel-inner>.item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}


























/* media queries */

@media (min-width: $screen-sm-min) {
  .banner .container,
  .banner .container-fluid {
    background-color: transparent;
  }

  .cta-home {
    .cta {
      .menu-item {
        a {
          float: left;
        }
        a:nth-child(odd) {
          margin-right: 1px;
          width: calc(50% - 1px);
        }
        a:nth-child(even) {
          width: 50%;
        }
      }
    }
  }

  .carousel-caption p {
    font-size: 32px;
  }

  .feat-links {
    text-align: center;
  }

  .data-posts .full-panel {
    padding: 0 20%;
  }

  .brand {
    margin: 7px 15px 4px;
  }

  .main-search input {
    font-size: 18px;
  }

  .content-blocks {
    .carousel {
      .show-thumbnails {
        .carousel-thumbnails {
          >a {
            flex: 1 1 32%;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .main-nav+.breadcrumb-container {
    margin-top: 52px;
  }

  .carousel {
    .carousel-indicators {
      width: 60%;
    }
  }

  .navigation.row+.breadcrumb-container.row {
    border-top: 15px solid var(--brand-primary);
    .breadcrumb {
      padding: 10px 5vw 10px calc(5vw + 220px);
    }
  }

  .main-search-container {
    bottom: 22vh;
  }

  .navbar-primary.affix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 900;
  }

  .navbar-collapse {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .navbar-primary {
    background-color: var(--brand-primary-dark-rgba);
    >.nav {
      display: block;
      >.menu-item.open:last-child {
        >.dropdown-menu {
          right: 0;
          left: auto;
        }
      }
    }
    .social-media {
      float: right;
      a {
        background-color: #fff;
        border-radius: 50%;
        display: inline-block;
        font-size: 18px;
        height: 30px;
        line-height: 30px;
        margin: 10px 2px;
        text-align: center;
        width: 30px;
      }
    }
    .navbar-form {
      padding: 0 5px;
      float: right;
      .form-group {
        float: none;
        width: auto;
      }
    }
  }

  .aside-width {
    float: left !important;
    clear: none;
  }

  .main-width {
    float: right !important;
    clear: none;
  }

  .banner {
    .carousel {
      .carousel-inner {
        .item {
          .carousel-caption {
            h1 {
              font-size: 3em;
            }
            p {
              font-size: 1.5em;
            }
            h1,
            p {
              //text-shadow: none;
            }
          }
        }
      }
    }

    .image-caption {
      >a {
        color: white;
      }
    }
  }

  .content-blocks {
    img.alignleft,
    img.alignright {
      max-width: 65%;
    }
    .row {
      .full-indent-width {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .full_width_image {
      div[style*="url("] {
        .btn-photo-info {
          display: block;
          bottom: 15px;
          left: 15px;
          right: auto;
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 1em;
          z-index: 999;
          pointer-events: none;
          &:hover,
          &:focus {
            border-radius: 100%;
          }
        }
      }
    }
    .feature_panel {
      .columns {
        flex-wrap: nowrap;
      }
    }
    .call_to_action {
      .cta-blocks {
        flex-direction: row;
        &.cta-individual {
          .cta-content {
            margin-bottom: 0px;
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .flex-map {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    .carousel {
      .show-thumbnails {
        margin-left: auto;
        margin-right: auto;
        .carousel-thumbnails {
          >a {
            border: 1px solid white;
            flex: 1 1 20%;
            min-width: 20%;
            .thumbnail {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .height-auto {
      min-height: auto !important;
    }
    &.common-blocks {
      margin-top: 30px;
      >div {
        margin-bottom: 0;
      }
    }
  }

  .cta-home {
    bottom: 0;
    height: 150px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 150;
    .cta {
      .menu-item {
        a {
          min-height: 150px;
          padding: 10px 0;
          background-color: $rgba-black;
          margin: 0 2px;
          border-top: 0px none;
          z-index: 300;
          width: calc(25% - 3px) !important;
          float: left;
        }
        i {
          font-size: 1.75em;
        }
        h3 {
          font-size: 24px;
          padding: 0;
        }
      }
      li:last-child a {
        margin-right: 0;
      }
      li:first-child a {
        margin-left: 0;
      }
    }
  }

  .graves {
    .graves-content {
      .gallery-block {
        .show-thumbnails {
          .fancybox {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .projects,
  .single-project {
    .aside-width {
      float: right !important;
      clear: none;
    }
    .main-width {
      float: left !important;
      clear: none;
    }
    .page-header {
      margin-left: 0;
    }
  }


  .content-blocks {
    .row {
      .full-indent-width {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .full_width_image {
      div[style*="url("] {
        .btn-photo-info {
          display: block;
          bottom: 15px;
          left: 15px;
          right: auto;
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 1em;
          z-index: 999;
          pointer-events: none;
          &:hover,
          &:focus {
            border-radius: 100%;
          }
        }
      }
    }
    .post_list,
    .post-list {
      .post-item {
        flex-flow: row wrap;
        border: 4px solid transparent;
        flex: 1 1 auto;
        .post-image {
          flex: 1 0 auto;
          width: 100%;
        }
        .post-text {
          flex: 1 1 auto;
        }
      }
    }

    #post-list,
    .post-list,
    .post-item,
    .project,
    .type-project {
      .post-type-project,
      .post-type-post {
        .post-text {
          width: calc(35% - 15px);
        }
      }
    }
  }

  footer {
    .footer-nav {
      display: flex;
      flex-flow: column wrap;
      >li {
        flex: 1 1 auto;
        padding: 0 20px 0 0;
        border-bottom: 0px none;
        padding-bottom: 0;
        ul li {
          display: inline-block;
        }
      }
    }
    .copyright {
      margin: 40px 0 10px;
      >p,
      .footer-nav2 {
        display: inline-block;
      }
    }
    .footer-nav2 {
      border-left: 1px solid #fff;
      line-height: 1;
      margin: 0 0 0 30px;
      padding: 0 0 0 20px;
    }
  }

  .modal {
    text-align: center;
    &:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
    }
    .modal-dialog {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }




  .splash,
  .splash .carousel-inner {
    height: 55vh;
    max-height: 500px;
  }

  .home {
    .splash,
    .splash .carousel-inner {
      height: 65vh;
      max-height: 700px;
    }

    .call_to_action {
      p,
      h3 {
        padding: 0;
      }
      h3{
        margin: 0;
        line-height: 1;
      }
    }
  }

  .navigation {
    background-color: transparent;
    position: relative;
    .navbar.affix-nav {
      position: absolute;
      top: 0px;
      width: 100%;
      z-index: 0;
    }
    .brand {
      display: block;
      float: left;
      height: 45px;
      margin: 0 30px 0 0;
      overflow: visible;
      padding: 0;
      position: relative;
      width: 150px;
      img {
        border-color: #fff;
        border-image: none;
        border-style: solid;
        border-width: 15px 10px 10px;
        display: block;
        height: auto;
        left: 0;
        position: absolute;
        top: 0px;
        width: 150px;
        z-index: 99;
        max-height: auto;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .carousel-caption p {
    font-size: 38px;
  }

  .sighting {
    border: 0px none;
  }

  .navbar-primary {
    a {
      font-size: 14px;
    }
    .navbar-nav>li>a {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      margin-right: 1px;
    }
  }
}

@media (min-width: $screen-md) {
  footer {
    .sponsors {
      margin-bottom: 0px;
      .sponsor-links {
        flex-flow: row nowrap;
        a {
          min-height: 125px;
        }
      }
    }
  }
}

@media (min-width: $screen-lg-min) {
  .carousel-caption p {
    font-size: 42px;
  }

  .sighting {
    border-bottom: 1px dotted #ccc;
  }

  footer {
    .sponsors {
      .sponsor-links {
        flex-flow: row nowrap;
        a {
          min-height: 125px;
        }
      }
    }
  }

  footer {
    .footer-nav {
      flex-flow: row;
      >li {
        ul li {
          display: block;
        }
      }
    }
  }

  .navbar-primary {
    a {
      font-size: 1em;
    }
  }

  .banner {
    .carousel {
      .carousel-inner {
        .item {
          .carousel-caption {
            h1 {
              max-width: 60%;
            }
            .carousel-description {
              max-width: 60%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .container {
    width: 1400px;
  }

  .navbar-primary {
    .navbar-nav>li>a {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }
}

//grave svg stylin'//
a.grave path {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal
}

.grave path:hover,
.grave path:active {
  fill: #f20017;
  stroke: #f20017;
  cursor: pointer;
}


.st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1 {
  clip-path: url(#SVGID_2_);
}

.st2 {
  clip-path: url(#SVGID_4_);
}

.st3 {
  clip-path: url(#SVGID_6_);
}

.st4 {
  clip-path: url(#SVGID_8_);
}

.st5 {
  clip-path: url(#SVGID_10_);
}

.st6 {
  clip-path: url(#SVGID_12_);
}

.st7 {
  clip-path: url(#SVGID_14_);
}

.st8 {
  clip-path: url(#SVGID_16_);
}

.st9 {
  clip-path: url(#SVGID_18_);
}

.st10 {
  clip-path: url(#SVGID_20_);
}

.st11 {
  clip-path: url(#SVGID_22_);
}

.st12 {
  clip-path: url(#SVGID_24_);
}

.st13 {
  clip-path: url(#SVGID_26_);
}

.st14 {
  font-family: $sans-serif;
}

.st15 {
  font-size: 12.45px;
}

.st16 {
  clip-path: url(#SVGID_28_);
}

.st17 {
  clip-path: url(#SVGID_30_);
}

.st18 {
  clip-path: url(#SVGID_32_);
}

.st19 {
  clip-path: url(#SVGID_34_);
}

.st20 {
  font-family: $sans-serif;
}

.st21 {
  font-size: 7.5px;
}

.st22 {
  clip-path: url(#SVGID_36_);
}

.st23 {
  clip-path: url(#SVGID_38_);
}

.st24 {
  clip-path: url(#SVGID_40_);
}

.st25 {
  clip-path: url(#SVGID_42_);
}

.st26 {
  clip-path: url(#SVGID_44_);
}

.st27 {
  clip-path: url(#SVGID_46_);
}

.st28 {
  clip-path: url(#SVGID_48_);
}

.st29 {
  clip-path: url(#SVGID_50_);
}

.st30 {
  clip-path: url(#SVGID_52_);
}

.st31 {
  clip-path: url(#SVGID_54_);
}

.st32 {
  clip-path: url(#SVGID_56_);
}

.st33 {
  clip-path: url(#SVGID_58_);
}

.st34 {
  clip-path: url(#SVGID_60_);
}

.st35 {
  clip-path: url(#SVGID_62_);
}

.st36 {
  clip-path: url(#SVGID_64_);
}

.st37 {
  clip-path: url(#SVGID_66_);
}

.st38 {
  clip-path: url(#SVGID_68_);
}

.st39 {
  clip-path: url(#SVGID_70_);
}

.st40 {
  clip-path: url(#SVGID_72_);
}

.st41 {
  clip-path: url(#SVGID_74_);
}

.st42 {
  clip-path: url(#SVGID_76_);
}

.st43 {
  clip-path: url(#SVGID_78_);
}

.st44 {
  clip-path: url(#SVGID_80_);
}

.st45 {
  clip-path: url(#SVGID_82_);
}

.st46 {
  clip-path: url(#SVGID_84_);
}

.st47 {
  clip-path: url(#SVGID_86_);
}

.st48 {
  clip-path: url(#SVGID_88_);
}

.st49 {
  clip-path: url(#SVGID_90_);
}

.st50 {
  clip-path: url(#SVGID_92_);
}

.st51 {
  clip-path: url(#SVGID_94_);
}

.st52 {
  clip-path: url(#SVGID_96_);
}

.st53 {
  clip-path: url(#SVGID_98_);
}

.st54 {
  clip-path: url(#SVGID_100_);
}

.st55 {
  clip-path: url(#SVGID_102_);
}

.st56 {
  clip-path: url(#SVGID_104_);
}

.st57 {
  clip-path: url(#SVGID_106_);
}

.st58 {
  clip-path: url(#SVGID_108_);
}

.st59 {
  clip-path: url(#SVGID_110_);
}

.st60 {
  clip-path: url(#SVGID_112_);
}

.st61 {
  clip-path: url(#SVGID_114_);
}

.st62 {
  clip-path: url(#SVGID_116_);
}

.st63 {
  clip-path: url(#SVGID_118_);
}

.st64 {
  clip-path: url(#SVGID_120_);
}

.st65 {
  clip-path: url(#SVGID_122_);
}

.st66 {
  clip-path: url(#SVGID_124_);
}

.st67 {
  clip-path: url(#SVGID_126_);
}

.st68 {
  clip-path: url(#SVGID_128_);
}

.st69 {
  clip-path: url(#SVGID_130_);
}

.st70 {
  clip-path: url(#SVGID_132_);
}

.st71 {
  clip-path: url(#SVGID_134_);
}

.st72 {
  clip-path: url(#SVGID_136_);
}

.st73 {
  clip-path: url(#SVGID_138_);
}

.st74 {
  clip-path: url(#SVGID_140_);
}

.st75 {
  clip-path: url(#SVGID_142_);
}

.st76 {
  clip-path: url(#SVGID_144_);
}

.st77 {
  clip-path: url(#SVGID_146_);
}

.st78 {
  clip-path: url(#SVGID_148_);
}

.st79 {
  clip-path: url(#SVGID_150_);
}

.st80 {
  clip-path: url(#SVGID_152_);
}

.st81 {
  clip-path: url(#SVGID_154_);
}

.st82 {
  clip-path: url(#SVGID_156_);
}

.st83 {
  clip-path: url(#SVGID_158_);
}

.st84 {
  clip-path: url(#SVGID_160_);
}

.st85 {
  clip-path: url(#SVGID_162_);
}

.st86 {
  clip-path: url(#SVGID_164_);
}

.st87 {
  clip-path: url(#SVGID_166_);
}

.st88 {
  clip-path: url(#SVGID_168_);
}

.st89 {
  clip-path: url(#SVGID_170_);
}

.st90 {
  clip-path: url(#SVGID_172_);
}

.st91 {
  clip-path: url(#SVGID_174_);
}

.st92 {
  clip-path: url(#SVGID_176_);
}

.st93 {
  clip-path: url(#SVGID_178_);
}

.st94 {
  clip-path: url(#SVGID_180_);
}

.st95 {
  clip-path: url(#SVGID_182_);
}

.st96 {
  clip-path: url(#SVGID_184_);
}

.st97 {
  clip-path: url(#SVGID_186_);
}

.st98 {
  clip-path: url(#SVGID_188_);
}

.st99 {
  clip-path: url(#SVGID_190_);
}

.st100 {
  clip-path: url(#SVGID_192_);
}

.st101 {
  clip-path: url(#SVGID_194_);
}

.st102 {
  clip-path: url(#SVGID_196_);
}

.st103 {
  clip-path: url(#SVGID_198_);
}

.st104 {
  clip-path: url(#SVGID_200_);
}

.st105 {
  clip-path: url(#SVGID_202_);
}

.st106 {
  clip-path: url(#SVGID_204_);
}

.st107 {
  clip-path: url(#SVGID_206_);
}

.st108 {
  clip-path: url(#SVGID_208_);
}

.st109 {
  clip-path: url(#SVGID_210_);
}

.st110 {
  clip-path: url(#SVGID_212_);
}

.st111 {
  clip-path: url(#SVGID_214_);
}

.st112 {
  clip-path: url(#SVGID_216_);
}

.st113 {
  clip-path: url(#SVGID_218_);
}

.st114 {
  clip-path: url(#SVGID_220_);
}

.st115 {
  clip-path: url(#SVGID_222_);
}

.st116 {
  clip-path: url(#SVGID_224_);
}

.st117 {
  clip-path: url(#SVGID_226_);
}

.st118 {
  clip-path: url(#SVGID_228_);
}

.st119 {
  clip-path: url(#SVGID_230_);
}

.st120 {
  clip-path: url(#SVGID_232_);
}

.st121 {
  clip-path: url(#SVGID_234_);
}

.st122 {
  clip-path: url(#SVGID_236_);
}

.st123 {
  clip-path: url(#SVGID_238_);
}

.st124 {
  clip-path: url(#SVGID_240_);
}

.st125 {
  clip-path: url(#SVGID_242_);
}

.st126 {
  clip-path: url(#SVGID_244_);
}

.st127 {
  clip-path: url(#SVGID_246_);
}

.st128 {
  clip-path: url(#SVGID_248_);
}

.st129 {
  clip-path: url(#SVGID_250_);
}

.st130 {
  clip-path: url(#SVGID_252_);
}

.st131 {
  clip-path: url(#SVGID_254_);
}

.st132 {
  clip-path: url(#SVGID_256_);
}

.st133 {
  clip-path: url(#SVGID_258_);
}

.st134 {
  clip-path: url(#SVGID_260_);
}

.st135 {
  clip-path: url(#SVGID_262_);
}

.st136 {
  clip-path: url(#SVGID_264_);
}

.st137 {
  clip-path: url(#SVGID_266_);
}

.st138 {
  clip-path: url(#SVGID_268_);
}

.st139 {
  clip-path: url(#SVGID_270_);
}

.st140 {
  clip-path: url(#SVGID_272_);
}

.st141 {
  clip-path: url(#SVGID_274_);
}

.st142 {
  clip-path: url(#SVGID_276_);
}

.st143 {
  clip-path: url(#SVGID_278_);
}

.st144 {
  clip-path: url(#SVGID_280_);
}

.st145 {
  clip-path: url(#SVGID_282_);
}

.st146 {
  clip-path: url(#SVGID_284_);
}

.st147 {
  clip-path: url(#SVGID_286_);
}

.st148 {
  clip-path: url(#SVGID_288_);
}

.st149 {
  clip-path: url(#SVGID_290_);
}

.st150 {
  clip-path: url(#SVGID_292_);
}

.st151 {
  clip-path: url(#SVGID_294_);
}

.st152 {
  clip-path: url(#SVGID_296_);
}

.st153 {
  clip-path: url(#SVGID_298_);
}

.st154 {
  clip-path: url(#SVGID_300_);
}

.st155 {
  clip-path: url(#SVGID_302_);
}

.st156 {
  clip-path: url(#SVGID_304_);
}

.st157 {
  clip-path: url(#SVGID_306_);
}

.st158 {
  clip-path: url(#SVGID_308_);
}

.st159 {
  clip-path: url(#SVGID_310_);
}

.st160 {
  clip-path: url(#SVGID_312_);
}

.st161 {
  clip-path: url(#SVGID_314_);
}

.st162 {
  clip-path: url(#SVGID_316_);
}

.st163 {
  clip-path: url(#SVGID_318_);
}

.st164 {
  clip-path: url(#SVGID_320_);
}

.st165 {
  clip-path: url(#SVGID_322_);
}

.st166 {
  clip-path: url(#SVGID_324_);
}

.st167 {
  clip-path: url(#SVGID_326_);
}

.st168 {
  clip-path: url(#SVGID_328_);
}

.st169 {
  clip-path: url(#SVGID_330_);
}

.st170 {
  clip-path: url(#SVGID_332_);
}

.st171 {
  clip-path: url(#SVGID_334_);
}

.st172 {
  clip-path: url(#SVGID_336_);
}

.st173 {
  clip-path: url(#SVGID_338_);
}

.st174 {
  clip-path: url(#SVGID_340_);
}

.st175 {
  clip-path: url(#SVGID_342_);
}

.st176 {
  clip-path: url(#SVGID_344_);
}

.st177 {
  clip-path: url(#SVGID_346_);
}

.st178 {
  clip-path: url(#SVGID_348_);
}

.st179 {
  clip-path: url(#SVGID_350_);
}

.st180 {
  clip-path: url(#SVGID_352_);
}

.st181 {
  clip-path: url(#SVGID_354_);
}

.st182 {
  clip-path: url(#SVGID_356_);
}

.st183 {
  clip-path: url(#SVGID_358_);
}

.st184 {
  clip-path: url(#SVGID_360_);
}

.st185 {
  clip-path: url(#SVGID_362_);
}

.st186 {
  clip-path: url(#SVGID_364_);
}

.st187 {
  clip-path: url(#SVGID_366_);
}

.st188 {
  clip-path: url(#SVGID_368_);
}

.st189 {
  clip-path: url(#SVGID_370_);
}

.st190 {
  clip-path: url(#SVGID_372_);
}

.st191 {
  clip-path: url(#SVGID_374_);
}

.st192 {
  clip-path: url(#SVGID_376_);
}

.st193 {
  clip-path: url(#SVGID_378_);
}

.st194 {
  clip-path: url(#SVGID_380_);
}

.st195 {
  clip-path: url(#SVGID_382_);
}

.st196 {
  clip-path: url(#SVGID_384_);
}

.st197 {
  clip-path: url(#SVGID_386_);
}

.st198 {
  clip-path: url(#SVGID_388_);
}

.st199 {
  clip-path: url(#SVGID_390_);
}

.st200 {
  clip-path: url(#SVGID_392_);
}

.st201 {
  clip-path: url(#SVGID_394_);
}

.st202 {
  clip-path: url(#SVGID_396_);
}

.st203 {
  clip-path: url(#SVGID_398_);
}

.st204 {
  clip-path: url(#SVGID_400_);
}

.st205 {
  clip-path: url(#SVGID_402_);
}

.st206 {
  clip-path: url(#SVGID_404_);
}

.st207 {
  clip-path: url(#SVGID_406_);
}

.st208 {
  clip-path: url(#SVGID_408_);
}

.st209 {
  clip-path: url(#SVGID_410_);
}

.st210 {
  clip-path: url(#SVGID_412_);
}

.st211 {
  clip-path: url(#SVGID_414_);
}

.st212 {
  clip-path: url(#SVGID_416_);
}

.st213 {
  clip-path: url(#SVGID_418_);
}

.st214 {
  clip-path: url(#SVGID_420_);
}

.st215 {
  clip-path: url(#SVGID_422_);
}

.st216 {
  clip-path: url(#SVGID_424_);
}

.st217 {
  clip-path: url(#SVGID_426_);
}

.st218 {
  clip-path: url(#SVGID_428_);
}

.st219 {
  clip-path: url(#SVGID_430_);
}

.st220 {
  clip-path: url(#SVGID_432_);
}

.st221 {
  clip-path: url(#SVGID_434_);
}

.st222 {
  clip-path: url(#SVGID_436_);
}

.st223 {
  clip-path: url(#SVGID_438_);
}

.st224 {
  clip-path: url(#SVGID_440_);
}

.st225 {
  clip-path: url(#SVGID_442_);
}

.st226 {
  clip-path: url(#SVGID_444_);
}

.st227 {
  clip-path: url(#SVGID_446_);
}

.st228 {
  clip-path: url(#SVGID_448_);
}

.st229 {
  clip-path: url(#SVGID_450_);
}

.st230 {
  clip-path: url(#SVGID_452_);
}

.st231 {
  clip-path: url(#SVGID_454_);
}

.st232 {
  clip-path: url(#SVGID_456_);
}

.st233 {
  clip-path: url(#SVGID_458_);
}

.st234 {
  clip-path: url(#SVGID_460_);
}

.st235 {
  clip-path: url(#SVGID_462_);
}

.st236 {
  clip-path: url(#SVGID_464_);
}

.st237 {
  clip-path: url(#SVGID_466_);
}

.st238 {
  clip-path: url(#SVGID_468_);
}

.st239 {
  clip-path: url(#SVGID_470_);
}

.st240 {
  clip-path: url(#SVGID_472_);
}

.st241 {
  clip-path: url(#SVGID_474_);
}

.st242 {
  clip-path: url(#SVGID_476_);
}

.st243 {
  clip-path: url(#SVGID_478_);
}

.st244 {
  clip-path: url(#SVGID_480_);
}

.st245 {
  clip-path: url(#SVGID_482_);
}

.st246 {
  clip-path: url(#SVGID_484_);
}

.st247 {
  clip-path: url(#SVGID_486_);
}

.st248 {
  clip-path: url(#SVGID_488_);
}

.st249 {
  clip-path: url(#SVGID_490_);
}

.st250 {
  clip-path: url(#SVGID_492_);
}

.st251 {
  clip-path: url(#SVGID_494_);
}

.st252 {
  clip-path: url(#SVGID_496_);
}

.st253 {
  clip-path: url(#SVGID_498_);
}

.st254 {
  clip-path: url(#SVGID_500_);
}

.st255 {
  clip-path: url(#SVGID_502_);
}

.st256 {
  clip-path: url(#SVGID_504_);
}

.st257 {
  clip-path: url(#SVGID_506_);
}

.st258 {
  clip-path: url(#SVGID_508_);
}

.st259 {
  clip-path: url(#SVGID_510_);
}

.st260 {
  clip-path: url(#SVGID_512_);
}

.st261 {
  clip-path: url(#SVGID_514_);
}

.st262 {
  clip-path: url(#SVGID_516_);
}

.st263 {
  clip-path: url(#SVGID_518_);
}

.st264 {
  clip-path: url(#SVGID_520_);
}

.st265 {
  clip-path: url(#SVGID_522_);
}

.st266 {
  clip-path: url(#SVGID_524_);
}

.st267 {
  clip-path: url(#SVGID_526_);
}

.st268 {
  clip-path: url(#SVGID_528_);
}

.st269 {
  clip-path: url(#SVGID_530_);
}

.st270 {
  clip-path: url(#SVGID_532_);
}

.st271 {
  clip-path: url(#SVGID_534_);
}

.st272 {
  clip-path: url(#SVGID_536_);
}

.st273 {
  clip-path: url(#SVGID_538_);
}

.st274 {
  clip-path: url(#SVGID_540_);
}

.st275 {
  clip-path: url(#SVGID_542_);
}

.st276 {
  clip-path: url(#SVGID_544_);
}

.st277 {
  clip-path: url(#SVGID_546_);
}

.st278 {
  clip-path: url(#SVGID_548_);
}

.st279 {
  clip-path: url(#SVGID_550_);
}

.st280 {
  clip-path: url(#SVGID_552_);
}

.st281 {
  clip-path: url(#SVGID_554_);
}

.st282 {
  clip-path: url(#SVGID_556_);
}

.st283 {
  clip-path: url(#SVGID_558_);
}

.st284 {
  clip-path: url(#SVGID_560_);
}

.st285 {
  clip-path: url(#SVGID_562_);
}

.st286 {
  clip-path: url(#SVGID_564_);
}

.st287 {
  clip-path: url(#SVGID_566_);
}

.st288 {
  clip-path: url(#SVGID_568_);
}

.st289 {
  clip-path: url(#SVGID_570_);
}

.st290 {
  clip-path: url(#SVGID_572_);
}

.st291 {
  clip-path: url(#SVGID_574_);
}

.st292 {
  clip-path: url(#SVGID_576_);
}

.st293 {
  clip-path: url(#SVGID_578_);
}

.st294 {
  clip-path: url(#SVGID_580_);
}

.st295 {
  clip-path: url(#SVGID_582_);
}

.st296 {
  clip-path: url(#SVGID_584_);
}

.st297 {
  clip-path: url(#SVGID_586_);
}

.st298 {
  clip-path: url(#SVGID_588_);
}

.st299 {
  clip-path: url(#SVGID_590_);
}

.st300 {
  clip-path: url(#SVGID_592_);
}

.st301 {
  clip-path: url(#SVGID_594_);
}

.st302 {
  clip-path: url(#SVGID_596_);
}

.st303 {
  clip-path: url(#SVGID_598_);
}

.st304 {
  clip-path: url(#SVGID_600_);
}

.st305 {
  clip-path: url(#SVGID_602_);
}

.st306 {
  clip-path: url(#SVGID_604_);
}

.st307 {
  clip-path: url(#SVGID_606_);
}

.st308 {
  clip-path: url(#SVGID_608_);
}

.st309 {
  clip-path: url(#SVGID_610_);
}

.st310 {
  clip-path: url(#SVGID_612_);
}

.st311 {
  clip-path: url(#SVGID_614_);
}

.st312 {
  clip-path: url(#SVGID_616_);
}

.st313 {
  clip-path: url(#SVGID_618_);
}

.st314 {
  clip-path: url(#SVGID_620_);
}

.st315 {
  clip-path: url(#SVGID_622_);
}

.st316 {
  clip-path: url(#SVGID_624_);
}

.st317 {
  clip-path: url(#SVGID_626_);
}

.st318 {
  clip-path: url(#SVGID_628_);
}

.st319 {
  clip-path: url(#SVGID_630_);
}

.st320 {
  clip-path: url(#SVGID_632_);
}

.st321 {
  clip-path: url(#SVGID_634_);
}

.st322 {
  clip-path: url(#SVGID_636_);
}

.st323 {
  clip-path: url(#SVGID_638_);
}

.st324 {
  clip-path: url(#SVGID_640_);
}

.st325 {
  clip-path: url(#SVGID_642_);
}

.st326 {
  clip-path: url(#SVGID_644_);
}

.st327 {
  clip-path: url(#SVGID_646_);
}

.st328 {
  clip-path: url(#SVGID_648_);
}

.st329 {
  clip-path: url(#SVGID_650_);
}

.st330 {
  clip-path: url(#SVGID_652_);
}

.st331 {
  clip-path: url(#SVGID_654_);
}

.st332 {
  clip-path: url(#SVGID_656_);
}

.st333 {
  clip-path: url(#SVGID_658_);
}

.st334 {
  clip-path: url(#SVGID_660_);
}

.st335 {
  clip-path: url(#SVGID_662_);
}

.st336 {
  clip-path: url(#SVGID_664_);
}

.st337 {
  clip-path: url(#SVGID_666_);
}

.st338 {
  clip-path: url(#SVGID_668_);
}

.st339 {
  clip-path: url(#SVGID_670_);
}

.st340 {
  clip-path: url(#SVGID_672_);
}

.st341 {
  clip-path: url(#SVGID_674_);
}

.st342 {
  clip-path: url(#SVGID_676_);
}

.st343 {
  clip-path: url(#SVGID_678_);
}

.st344 {
  clip-path: url(#SVGID_680_);
}

.st345 {
  clip-path: url(#SVGID_682_);
}

.st346 {
  clip-path: url(#SVGID_684_);
}

.st347 {
  clip-path: url(#SVGID_686_);
}

.st348 {
  clip-path: url(#SVGID_688_);
}

.st349 {
  clip-path: url(#SVGID_690_);
}

.st350 {
  clip-path: url(#SVGID_692_);
}

.st351 {
  clip-path: url(#SVGID_694_);
}

.st352 {
  clip-path: url(#SVGID_696_);
}

.st353 {
  clip-path: url(#SVGID_698_);
}

.st354 {
  clip-path: url(#SVGID_700_);
}

.st355 {
  clip-path: url(#SVGID_702_);
}

.st356 {
  clip-path: url(#SVGID_704_);
}

.st357 {
  clip-path: url(#SVGID_706_);
}

.st358 {
  clip-path: url(#SVGID_708_);
}

.st359 {
  clip-path: url(#SVGID_710_);
}

.st360 {
  clip-path: url(#SVGID_712_);
}

.st361 {
  clip-path: url(#SVGID_714_);
}

.st362 {
  clip-path: url(#SVGID_716_);
}

.st363 {
  clip-path: url(#SVGID_718_);
}

.st364 {
  clip-path: url(#SVGID_720_);
}

.st365 {
  clip-path: url(#SVGID_722_);
}

.st366 {
  clip-path: url(#SVGID_724_);
}

.st367 {
  clip-path: url(#SVGID_726_);
}

.st368 {
  clip-path: url(#SVGID_728_);
}

.st369 {
  clip-path: url(#SVGID_730_);
}

.st370 {
  clip-path: url(#SVGID_732_);
}

.st371 {
  clip-path: url(#SVGID_734_);
}

.st372 {
  clip-path: url(#SVGID_736_);
}

.st373 {
  clip-path: url(#SVGID_738_);
}

.st374 {
  clip-path: url(#SVGID_740_);
}

.st375 {
  clip-path: url(#SVGID_742_);
}

.st376 {
  clip-path: url(#SVGID_744_);
}

.st377 {
  clip-path: url(#SVGID_746_);
}

.st378 {
  clip-path: url(#SVGID_748_);
}

.st379 {
  clip-path: url(#SVGID_750_);
}

.st380 {
  clip-path: url(#SVGID_752_);
}

.st381 {
  clip-path: url(#SVGID_754_);
}

.st382 {
  clip-path: url(#SVGID_756_);
}

.st383 {
  clip-path: url(#SVGID_758_);
}

.st384 {
  clip-path: url(#SVGID_760_);
}

.st385 {
  clip-path: url(#SVGID_762_);
}

.st386 {
  clip-path: url(#SVGID_764_);
}

.st387 {
  clip-path: url(#SVGID_766_);
}

.st388 {
  clip-path: url(#SVGID_768_);
}

.st389 {
  clip-path: url(#SVGID_770_);
}

.st390 {
  clip-path: url(#SVGID_772_);
}

.st391 {
  clip-path: url(#SVGID_774_);
}

.st392 {
  clip-path: url(#SVGID_776_);
}

.st393 {
  clip-path: url(#SVGID_778_);
}

.st394 {
  clip-path: url(#SVGID_780_);
}

.st395 {
  clip-path: url(#SVGID_782_);
}

.st396 {
  clip-path: url(#SVGID_784_);
}

.st397 {
  clip-path: url(#SVGID_786_);
}

.st398 {
  clip-path: url(#SVGID_788_);
}

.st399 {
  clip-path: url(#SVGID_790_);
}

.st400 {
  clip-path: url(#SVGID_792_);
}

.st401 {
  clip-path: url(#SVGID_794_);
}

.st402 {
  clip-path: url(#SVGID_796_);
}

.st403 {
  clip-path: url(#SVGID_798_);
}

.st404 {
  clip-path: url(#SVGID_800_);
}

.st405 {
  clip-path: url(#SVGID_802_);
}

.st406 {
  clip-path: url(#SVGID_804_);
}

.st407 {
  clip-path: url(#SVGID_806_);
}

.st408 {
  clip-path: url(#SVGID_808_);
}

.st409 {
  clip-path: url(#SVGID_810_);
}

.st410 {
  clip-path: url(#SVGID_812_);
}

.st411 {
  clip-path: url(#SVGID_814_);
}

.st412 {
  clip-path: url(#SVGID_816_);
}

.st413 {
  clip-path: url(#SVGID_818_);
}

.st414 {
  clip-path: url(#SVGID_820_);
}

.st415 {
  clip-path: url(#SVGID_822_);
}

.st416 {
  clip-path: url(#SVGID_824_);
}

.st417 {
  clip-path: url(#SVGID_826_);
}

.st418 {
  clip-path: url(#SVGID_828_);
}

.st419 {
  clip-path: url(#SVGID_830_);
}

.st420 {
  clip-path: url(#SVGID_832_);
}

.st421 {
  clip-path: url(#SVGID_834_);
}

.st422 {
  clip-path: url(#SVGID_836_);
}

.st423 {
  clip-path: url(#SVGID_838_);
}

.st424 {
  clip-path: url(#SVGID_840_);
}

.st425 {
  clip-path: url(#SVGID_842_);
}

.st426 {
  clip-path: url(#SVGID_844_);
}

.st427 {
  clip-path: url(#SVGID_846_);
}

.st428 {
  clip-path: url(#SVGID_848_);
}

.st429 {
  clip-path: url(#SVGID_850_);
}

.st430 {
  clip-path: url(#SVGID_852_);
}

.st431 {
  clip-path: url(#SVGID_854_);
}

.st432 {
  clip-path: url(#SVGID_856_);
}

.st433 {
  clip-path: url(#SVGID_858_);
}

.st434 {
  clip-path: url(#SVGID_860_);
}

.st435 {
  clip-path: url(#SVGID_862_);
}

.st436 {
  clip-path: url(#SVGID_864_);
}

.st437 {
  clip-path: url(#SVGID_866_);
}

.st438 {
  clip-path: url(#SVGID_868_);
}

.st439 {
  clip-path: url(#SVGID_870_);
}

.st440 {
  clip-path: url(#SVGID_872_);
}

.st441 {
  clip-path: url(#SVGID_874_);
}

.st442 {
  clip-path: url(#SVGID_876_);
}

.st443 {
  clip-path: url(#SVGID_878_);
}

.st444 {
  clip-path: url(#SVGID_880_);
}

.st445 {
  clip-path: url(#SVGID_882_);
}

.st446 {
  clip-path: url(#SVGID_884_);
}

.st447 {
  clip-path: url(#SVGID_886_);
}

.st448 {
  clip-path: url(#SVGID_888_);
}

.st449 {
  clip-path: url(#SVGID_890_);
}

.st450 {
  clip-path: url(#SVGID_892_);
}

.st451 {
  clip-path: url(#SVGID_894_);
}

.st452 {
  clip-path: url(#SVGID_896_);
}

.st453 {
  clip-path: url(#SVGID_898_);
}

.st454 {
  clip-path: url(#SVGID_900_);
}

.st455 {
  clip-path: url(#SVGID_902_);
}

.st456 {
  clip-path: url(#SVGID_904_);
}

.st457 {
  clip-path: url(#SVGID_906_);
}

.st458 {
  clip-path: url(#SVGID_908_);
}

.st459 {
  clip-path: url(#SVGID_910_);
}

.st460 {
  clip-path: url(#SVGID_912_);
}

.st461 {
  clip-path: url(#SVGID_914_);
}

.st462 {
  clip-path: url(#SVGID_916_);
}

.st463 {
  clip-path: url(#SVGID_918_);
}

.st464 {
  clip-path: url(#SVGID_920_);
}

.st465 {
  clip-path: url(#SVGID_922_);
}

.st466 {
  clip-path: url(#SVGID_924_);
}

.st467 {
  clip-path: url(#SVGID_926_);
}

.st468 {
  clip-path: url(#SVGID_928_);
}

.st469 {
  clip-path: url(#SVGID_930_);
}

.st470 {
  clip-path: url(#SVGID_932_);
}

.st471 {
  clip-path: url(#SVGID_934_);
}

.st472 {
  clip-path: url(#SVGID_936_);
}

.st473 {
  clip-path: url(#SVGID_938_);
}

.st474 {
  clip-path: url(#SVGID_940_);
}

.st475 {
  clip-path: url(#SVGID_942_);
}

.st476 {
  clip-path: url(#SVGID_944_);
}

.st477 {
  clip-path: url(#SVGID_946_);
}

.st478 {
  clip-path: url(#SVGID_948_);
}

.st479 {
  clip-path: url(#SVGID_950_);
}

.st480 {
  clip-path: url(#SVGID_952_);
}

.st481 {
  clip-path: url(#SVGID_954_);
}

.st482 {
  clip-path: url(#SVGID_956_);
}

.st483 {
  clip-path: url(#SVGID_958_);
}

.st484 {
  clip-path: url(#SVGID_960_);
}

.st485 {
  clip-path: url(#SVGID_962_);
}

.st486 {
  clip-path: url(#SVGID_964_);
}

.st487 {
  clip-path: url(#SVGID_966_);
}

.st488 {
  clip-path: url(#SVGID_968_);
}

.st489 {
  clip-path: url(#SVGID_970_);
}

.st490 {
  clip-path: url(#SVGID_972_);
}

.st491 {
  clip-path: url(#SVGID_974_);
}

.st492 {
  clip-path: url(#SVGID_976_);
}

.st493 {
  clip-path: url(#SVGID_978_);
}

.st494 {
  clip-path: url(#SVGID_980_);
}

.st495 {
  clip-path: url(#SVGID_982_);
}

.st496 {
  clip-path: url(#SVGID_984_);
}

.st497 {
  clip-path: url(#SVGID_986_);
}

.st498 {
  clip-path: url(#SVGID_988_);
}

.st499 {
  clip-path: url(#SVGID_990_);
}

.st500 {
  clip-path: url(#SVGID_992_);
}

.st501 {
  clip-path: url(#SVGID_994_);
}

.st502 {
  clip-path: url(#SVGID_996_);
}

.st503 {
  clip-path: url(#SVGID_998_);
}

.st504 {
  clip-path: url(#SVGID_1000_);
}

.st505 {
  clip-path: url(#SVGID_1002_);
}

.st506 {
  clip-path: url(#SVGID_1004_);
}

.st507 {
  clip-path: url(#SVGID_1006_);
}

.st508 {
  clip-path: url(#SVGID_1008_);
}

.st509 {
  clip-path: url(#SVGID_1010_);
}

.st510 {
  clip-path: url(#SVGID_1012_);
}

.st511 {
  clip-path: url(#SVGID_1014_);
}

.st512 {
  clip-path: url(#SVGID_1016_);
}

.st513 {
  clip-path: url(#SVGID_1018_);
}

.st514 {
  clip-path: url(#SVGID_1020_);
}

.st515 {
  clip-path: url(#SVGID_1022_);
}

.st516 {
  clip-path: url(#SVGID_1024_);
}

.st517 {
  clip-path: url(#SVGID_1026_);
}

.st518 {
  clip-path: url(#SVGID_1028_);
}

.st519 {
  clip-path: url(#SVGID_1030_);
}

.st520 {
  clip-path: url(#SVGID_1032_);
}

.st521 {
  clip-path: url(#SVGID_1034_);
}

.st522 {
  clip-path: url(#SVGID_1036_);
}

.st523 {
  clip-path: url(#SVGID_1038_);
}

.st524 {
  clip-path: url(#SVGID_1040_);
}

.st525 {
  clip-path: url(#SVGID_1042_);
}

.st526 {
  clip-path: url(#SVGID_1044_);
}

.st527 {
  clip-path: url(#SVGID_1046_);
}

.st528 {
  clip-path: url(#SVGID_1048_);
}

.st529 {
  clip-path: url(#SVGID_1050_);
}

.st530 {
  clip-path: url(#SVGID_1052_);
}

.st531 {
  clip-path: url(#SVGID_1054_);
}

.st532 {
  clip-path: url(#SVGID_1056_);
}

.st533 {
  clip-path: url(#SVGID_1058_);
}

.st534 {
  clip-path: url(#SVGID_1060_);
}

.st535 {
  clip-path: url(#SVGID_1062_);
}

.st536 {
  clip-path: url(#SVGID_1064_);
}

.st537 {
  clip-path: url(#SVGID_1066_);
}

.st538 {
  clip-path: url(#SVGID_1068_);
}

.st539 {
  clip-path: url(#SVGID_1070_);
}

.st540 {
  clip-path: url(#SVGID_1072_);
}

.st541 {
  clip-path: url(#SVGID_1074_);
}

.st542 {
  clip-path: url(#SVGID_1076_);
}

.st543 {
  clip-path: url(#SVGID_1078_);
}

.st544 {
  clip-path: url(#SVGID_1080_);
}

.st545 {
  clip-path: url(#SVGID_1082_);
}

.st546 {
  clip-path: url(#SVGID_1084_);
}

.st547 {
  clip-path: url(#SVGID_1086_);
}

.st548 {
  clip-path: url(#SVGID_1088_);
}

.st549 {
  clip-path: url(#SVGID_1090_);
}

.st550 {
  clip-path: url(#SVGID_1092_);
}

.st551 {
  clip-path: url(#SVGID_1094_);
}

.st552 {
  clip-path: url(#SVGID_1096_);
}

.st553 {
  clip-path: url(#SVGID_1098_);
}

.st554 {
  fill: none;
  stroke: #808080;
  stroke-width: 0.75;
  stroke-linecap: round;
  stroke-miterlimit: 8;
}

.st555 {
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-miterlimit: 8;
}

.st556 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-miterlimit: 8;
}

.st557 {
  fill: #FFFFFF;
}

.st558 {
  fill: none;
  stroke: #FF0000;
  stroke-width: 0.75;
  stroke-linejoin: round;
  stroke-miterlimit: 8;
}

.st559 {
  fill: none;
  stroke: #FF0000;
  stroke-width: 0.75;
  stroke-linejoin: round;
  stroke-miterlimit: 8;
  stroke-dasharray: 6, 2.25, 0.75, 2.25;
}

.st560 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.75;
  stroke-linecap: round;
  stroke-miterlimit: 8;
}

.st561 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.75;
  stroke-linejoin: round;
  stroke-miterlimit: 8;
}

.st562 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-miterlimit: 8;
}

.st563 {
  clip-path: url(#SVGID_1100_);
}


// grave svg //
